import React from "react"
import mapMarkerIcon from "@iconify/icons-fa/map-marker"

import "./Adresse.less"
import Icon from "@iconify/react"
import { Link } from "gatsby"

export default function Adresse() {
  return (
    <div className="adresse">
      <h2>ADRESSE</h2>
      <span className="name">MOBSTER BAR</span>
      <span className="adresse-line">8 rue de Crussol</span>
      <span className="adresse-line">75011 PARIS</span>
      <Link
        className="map-marker"
        to="https://g.page/mobster-bar?share"
        target="_blank"
      >
        <Icon icon={mapMarkerIcon} />
        <span>Voir sur Maps</span>
      </Link>
    </div>
  )
}
