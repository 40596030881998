import React from "react"
import Cadre from "../Cadre/Cadre"

import "./APropos.less"

export default function APropos() {
  return (
    <div className="a-propos">
      <Cadre
        color="#E6A133"
        widthX={3}
        widthY={1}
        ratioX={0.6}
        ratioY={0.3}
        paddingContent={0.5}
        widthLargeLine={2}
        widthLightLine={1}
        displayDivider={false}
        styleContent={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <h2>A propos</h2>
      </Cadre>
      <p>
      Une cabine téléphonique des années 1920, voilà le seul indice que vous verrez de l'extérieur. Décrochez le combiné et écoutez le message, il vous permettra de trouver le code à saisir sur le téléphone, sésame pour accéder à notre speakeasy (du nom des bars clandestins de l'époque de la prohibition). La décoration Art-déco de notre bar caché vous plongera dans un univers idéal pour déguster de délicieux cocktails sur des airs de jazz.
      </p>
    </div>
  )
}
