import React, { ReactElement, ReactNode } from "react"
import { Creneau, HorairesOuverture } from "../../types"

import "./Horaires.less"

interface HorairesProps {
  horaires?: HorairesOuverture
}

const buildCreneauItemFromData = (creneau: Creneau): ReactElement => {
  return (
    <div className="item-creneau" key={`${creneau.contentful_id}-creneau`}>
      <span className="dejeuner">
        {creneau.dejeunerClosed
          ? "Fermé"
          : `${creneau.dejeunerStart} - ${creneau.dejeunerEnd}`}
      </span>
      <span className="diner">
        {creneau.dinerClosed
          ? "Fermé"
          : `${creneau.dinerStart} - ${creneau.dinerEnd}`}
      </span>
    </div>
  )
}

export default function Horaires({ horaires }: HorairesProps) {
  return (
    <div className="horaires">
      <h2>HORAIRES</h2>
      {horaires === undefined ? (
        <p>Impossible d'afficher les horaires</p>
      ) : (
        <div className="list-horaire">
          <div className="list-days">
            {horaires.creneaux.map(creneau => (
              <span key={`${creneau.contentful_id}-day`}>
                {creneau.dayName}
              </span>
            ))}
          </div>
          <div className="list-creneaux">
            <div className="item-creneau"><span className="dejeuner">19:00 - 1:00</span></div>
            <div className="item-creneau"><span className="dejeuner">19:00 - 1:00</span></div>
            <div className="item-creneau"><span className="dejeuner">19:00 - 1:00</span></div>
            <div className="item-creneau"><span className="dejeuner">19:00 - 1:00</span></div>
            <div className="item-creneau"><span className="dejeuner">19:00 - 2:00</span></div>
            <div className="item-creneau"><span className="dejeuner">19:00 - 2:00</span></div>
            <div className="item-creneau"><span className="dejeuner">19:00 - 1:00</span></div>
          </div>
        </div>
      )}
    </div>
  )
}
