import React, { useState } from "react"
import Img from "gatsby-image"

import { MobsterBarAlbum } from "../../types"

import "./AlbumSection.less"

interface AlbumProps {
  album: MobsterBarAlbum
}
export default function AlbumSection({ album }: AlbumProps) {
  const [expanded, setExpanded] = useState<boolean>(false)

  const expandAlbum = () => {
    setExpanded(true)
  }
  return (
    <div className="album">
          {album.mobsterBarAlbumItem.map((item,i) => (
            <div
              style={{ gridArea: "p"+i }}
              className={
                item.mobileVisible || expanded
                  ? "album-item"
                  : "album-item not-mobile"
              }
              key={item.contentful_id}
            >
              <Img
                fluid={item.image.fluid}
                alt={item.image.description}
                key={item.image.contentful_id}
              />
            </div>
          ))}
    </div>
  )
}
