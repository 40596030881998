import React from "react"
import { Image } from "../../types"
import Img from "gatsby-image"
import Cadre from "../Cadre/Cadre"
import { Icon } from "@iconify/react"
import instagramIcon from "@iconify/icons-fa/instagram"
import facebookIcon from "@iconify/icons-fa/facebook"
import tripadvisorIcon from "@iconify/icons-fa/tripadvisor"
import linkedinIcon from "@iconify/icons-fa/linkedin"
import twitterIcon from "@iconify/icons-fa/twitter"
import pinterestIcon from "@iconify/icons-fa/pinterest"
import phoneIcon from "@iconify/icons-fa/phone"
import mailIcon from "@iconify/icons-fa/envelope"

import "./Header.less"
import { Link } from "gatsby"

interface HeaderProps {
  logo?: Image
  menu?: Image
}

export default function Header({ logo, menu }: HeaderProps) {
  return (
    <header className="header" style={{ padding: "1em", display: "flex" }}>
      <Cadre
        color="#E6A133"
        widthX={4}
        widthY={4}
        ratioX={0.5}
        ratioY={0.5}
        paddingContent={0.5}
        widthLargeLine={2}
        widthLightLine={1}
        style={{ flex: 1 }}
        styleContent={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <>
          <div className="flex-container">
            <div className="socials">
              <div className="left">
                <Link
                  to="https://www.instagram.com/mobster_bar"
                  target="_blank"
                >
                  <Icon icon={instagramIcon} />
                </Link>
                <Link
                  to="https://www.facebook.com/MobsterBar/"
                  target="_blank"
                >
                  <Icon icon={facebookIcon} />
                </Link>
                <Link
                  to="https://www.tripadvisor.fr/Restaurant_Review-g187147-d19720720-Reviews-Mobster_Bar-Paris_Ile_de_France.html"
                  target="_blank"
                >
                  <Icon icon={tripadvisorIcon} />
                </Link>
              </div>
              <div className="right">
                <Link
                  to="https://www.linkedin.com/company/mobster-bar/"
                  target="_blank"
                >
                  <Icon icon={linkedinIcon} />
                </Link>
                <Link
                  to="https://www.pinterest.fr/Mobster_Bar"
                  target="_blank"
                >
                  <Icon icon={pinterestIcon} />
                </Link>
                <Link to="https://twitter.com/mobsterbar" target="_blank">
                  <Icon icon={twitterIcon} />
                </Link>
              </div>
            </div>
            <div className="title">
              {logo !== undefined && (
                <Img
                  fluid={logo.fluid}
                  alt={logo.description}
                  style={{ margin: "auto" }}
                />
              )}
              {/* <h1>Mobster Bar</h1> */}
              <br />
              <span>L’entrée du speakeasy se fait via un code secret sur une mystérieuse cabine téléphonique.</span>
              <br /><span>Entrez et savourez de délicieux cocktails sur des airs de jazz</span>
            </div>
            {/* <div className="actions">
              <Link to="/menu/" target="_blank" className="btn btn-black">
                Voir le menu
              </Link>
              <Link to="#livraison" className="btn btn-white">
                Commander
              </Link>
            </div> */}
          </div>
          <div className="header-contact">
            <div className="desktop-version">
              <a href="mailto:mobsterbar@gmail.com">
                mobsterbar@gmail.com
              </a>
              <a href="tel:+33963618478">09 63 61 84 78</a>
            </div>
            <div className="mobile-version">
              <a href="mailto:mobsterbar@gmail.com">
                <Icon icon={mailIcon} />
              </a>
              <a href="tel:+33963618478">
                <Icon icon={phoneIcon} />
              </a>
            </div>
          </div>
        </>
      </Cadre>
    </header>
  )
}
