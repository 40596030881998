import React from "react"
import instagramIcon from "@iconify/icons-fa/instagram"
import facebookIcon from "@iconify/icons-fa/facebook"
import tripadvisorIcon from "@iconify/icons-fa/tripadvisor"
import pinterestIcon from "@iconify/icons-fa/pinterest"

import "./Footer.less"
import { Link } from "gatsby"
import Icon from "@iconify/react"

export default function Footer() {
  return (
    <footer>
      <div className="contact">
        <a href="mailto:mobsterbar@gmail.com">mobsterbar@gmail.com</a>
        <a href="tel:+33963618478">09 63 61 84 78</a>
      </div>
      <div className="center">
        <h3>MOBSTER BAR</h3>
        <div className="footerSocials">
          <Link
            to="https://www.instagram.com/mobster_bar"
            target="_blank"
          >
            <Icon icon={instagramIcon} />
          </Link>
          <Link to="https://www.facebook.com/MobsterBar/" target="_blank">
            <Icon icon={facebookIcon} />
          </Link>
          <Link
            to="https://www.tripadvisor.fr/Restaurant_Review-g187147-d19720720-Reviews-Mobster_Bar-Paris_Ile_de_France.html"
            target="_blank"
          >
            <Icon icon={tripadvisorIcon} />
          </Link>
          <Link to="https://www.pinterest.fr/Mobster_Bar" target="_blank">
            <Icon icon={pinterestIcon} />
          </Link>
        </div>
      </div>
      <div className="copyright">
        <span>© Mobster Bar</span>
        <span>Tous droits réservés</span>
      </div>
    </footer>
  )
}
